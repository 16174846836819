import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import RowanetLogo from '../pics/layer1.svg';

const Menu = ({ menuData }) => {
    const renderDropdownMenu = (children, parentId) => { //Rekurzivně volá sebe samu, aby vykreslila všechny vnořené položky menu
        return (
            <ul className={`dropdown-menu ${parentId ? "submenu" : ""}`} aria-labelledby={`navbarDropdown${parentId}`}>
                {children.map((child) => (
                    <li key={child.id} className={child.attributes.children.data.length > 0 ? "dropdown-submenu" : ""}>
                        <Link className="dropdown-item" to={child.attributes.url}>
                            {child.attributes.title}
                        </Link>
                        {child.attributes.children.data.length > 0 &&
                            renderDropdownMenu(child.attributes.children.data, child.id)}
                    </li>
                ))}
            </ul>
        );
    };
    

    const renderMenuItems = (items) => (
        items.map((item) => (
            <li key={item.id} className={`nav-item ${item.attributes.children.data.length > 0 ? "dropdown" : ""}`}> {/* Rozliší mezi přímým odkazem a rozbalovací nabídkou*/}
                {item.attributes.url ? (
                    <Link to={item.attributes.url} className="nav-link custom-link text-white" target={item.attributes.target || '_self'}>
                        {item.attributes.title}
                    </Link>
                ) : (
                    <div className="nav-link dropdown-toggle text-white" id={`navbarDropdown${item.id}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {item.attributes.title}
                    </div>
                )}
                {item.attributes.children.data.length > 0 && renderDropdownMenu(item.attributes.children.data, item.id)} {/* Vykreslí menu pro potomky v menu*/}
            </li>
        ))
    );

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-blue">
            <a href="/" className="navbar-logo-link">
                <img src={RowanetLogo} alt="Logo" className="navbar-logo"/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto">
                    {menuData && renderMenuItems(menuData.attributes.items.data)} {/* Základní tělo menu podle položek z menu definovaném ve Strapi*/}
                </ul>
            </div>
        </nav>
    );
};

export default Menu;
