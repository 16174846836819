import React from 'react';
import DOMPurify from 'dompurify';
import UseFetchData from '../hooks/UseFetchData';

function MetaPage({ page }) {
    const { data: output, loading, error } = UseFetchData(`${process.env.REACT_APP_API_URL}/api/meta-pages?filters[route][$eq]=${page}&populate=*`);

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;
    if (!output || output.length === 0) {
        return <div>Žádná data</div>;
    }

    const pageData = output[0];

    // Zpracování dat s kontrolou existence
    const topContent = pageData?.attributes.Top?.map(item => ({
        id: item.id,
        content: DOMPurify.sanitize(item.mainContent) // Sanitizace obsahu
    })) ?? [];
    const coreContent = pageData?.attributes.Core?.map(item => ({
        id: item.id,
        content: DOMPurify.sanitize(item.mainContent) // Sanitizace obsahu
    })) ?? [];
    const footerContent = pageData?.attributes.Footer?.map(item => ({
        id: item.id,
        content: DOMPurify.sanitize(item.mainContent) // Sanitizace obsahu
    })) ?? [];

    // Funkce pro vykreslení sanitizovaného HTML obsahu
    const renderHTMLContent = (content) => ({ __html: content });

    return (
        <div className="pozadi_">
            <div className="pozadi">
            <div className="container">
                <div className='img-fluid'>
                    {topContent.map(item => (
                        <div key={item.id} dangerouslySetInnerHTML={renderHTMLContent(item.content)} />
                    ))}
                    {coreContent.map(item => (
                        <div key={item.id} dangerouslySetInnerHTML={renderHTMLContent(item.content)} />
                    ))}
                </div>
            </div>
                <div className="pozadi_bile">
            {footerContent.map(item => (
                <div key={item.id} dangerouslySetInnerHTML={renderHTMLContent(item.content)} />
            ))}
                </div>
        </div>
        </div>
    );
}

export default MetaPage;
