import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Menu from './components/subPages/Menu';
import UseFetchData from './components/hooks/UseFetchData';
import MetaPage from './components/pages/MetaPage';

function App() {
    const { data: menuData, loading: loadingMenu, error: errorMenu } = UseFetchData(`${process.env.REACT_APP_API_URL}/api/menus?nested&populate=*`);
    const { data: menuDataForPages, loading: loadingMenuForPages, error: errorMenuForPages } = UseFetchData(`${process.env.REACT_APP_API_URL}/api/menus?nested&populate=*`);

    if (loadingMenu || loadingMenuForPages) return <div>Loading...</div>;
    if (errorMenu || errorMenuForPages) return <div>Error: {errorMenu.message}</div>;

    const createRoutesFromMenu = (menuItems) => { //Vytvoří cesty na jednotlivé stránky v menu a jeho jednotlivých položkách
        return menuItems.map((item) => {
            const hasChildren = item.attributes.children.data.length > 0;
            const mainRoute = (
                <Route key={item.id} path={item.attributes.url} element={<MetaPage page={item.attributes.url} />} />
            );
            const childRoutes = hasChildren ? createRoutesFromMenu(item.attributes.children.data) : [];
            return [mainRoute, ...childRoutes];
        }).flat();
    };

    const routesForMenu = menuData && menuData.length > 0 ? createRoutesFromMenu(menuData[0].attributes.items.data) : []; //Data pro hlavní menu v listě
    const routesForPages = menuDataForPages && menuDataForPages.length > 0 ? createRoutesFromMenu(menuData[1].attributes.items.data) : []; //Data pro odkazy přímo na stránkách
    
    return (
        <Router>
            <Menu menuData={menuData[0]} />
            <Routes>
                <Route path="/" element={<Navigate replace to="/uvod" />} />  
                {routesForMenu}
                {routesForPages}
            </Routes>
        </Router>
    );
}

export default App;
