import { useState, useEffect } from 'react';

const UseFetchData = (url) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => { //Asynchroní fetch dat s parametrem URL
            try 
            {
                const response = await fetch(url);
                if (!response.ok) 
                {
                    throw new Error(`Network response was not ok, status: ${response.status}`);
                }
                const jsonData = await response.json();
                setData(jsonData.data);
                setLoading(false);
            } catch (error) 
            {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    return { data, loading, error };
};

export default UseFetchData;
